import { defineStore, storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { toast } from '../native/toast';
import { newTextKey } from './storyworld';
import { SocialSpace, Image, ImageCollection } from '@/shared/types/static-types';
import { unreorderableCreatesocialspaceKeys } from '@/shared/statics/constants';
import { getWorld, createWorld, deleteWorld, editWorld, editWorldAuto } from '@/shared/actions/worlds';
import { reorderImageCollectionImages, getVisualCollections } from '@/shared/actions/imagesCollections';
import { imageStore } from '@/shared/pinia-store/images';
import logger from '@/shared/services/logger';

export interface ISocialSpaceCreatorState {
  currentSocialSpace: SocialSpace | null | any;
  isLoading: boolean;
  currentImageCollections: ImageCollection[];
  currentImageCollectionsChanged: boolean;
  _autosaveEnabled: boolean;
  isDirty: boolean;
  isDropDownCollapsed: boolean;
  isSaving: boolean;
  isDone: boolean;
  autosaveFailures: number;
  chatRooms: any[];
}

export const useSocialSpace = defineStore('social-space-creator', {
  state: (): ISocialSpaceCreatorState => ({
    currentSocialSpace: null,
    isLoading: false,
    currentImageCollections: [],
    currentImageCollectionsChanged: false,
    _autosaveEnabled: false,
    isSaving: false,
    isDirty: false,
    isDone: false,
    autosaveFailures: 0,
    chatRooms: [],
    isDropDownCollapsed: true,
  }),
  actions: {
    markAsDirty() {
      if (!this.autosaveEnabled) return;
      this.isDirty = false;
      this.isDirty = true;
    },
    markIsDone() {
      this.isDone = true;
    },
    setAutosaveEnabled(val: boolean) {
      this._autosaveEnabled = val;
    },
    async fetchownVisualCollections(id: string) {
      try {
        const response = await getVisualCollections({ page: 1, ownerType: 'world', ownerId: id as string });
        this.currentSocialSpace.collections = response.results;
      } catch (_e) {}
    },
    async loadEditor(id?: string) {
      const { reset: resetImageStore } = imageStore();
      resetImageStore();
      let socialSpaceInstance: SocialSpace;
      this.clearAll();
      try {
        this.isLoading = true;
        if (!id) {
          this.initSocialSpace();
          this.currentSocialSpace.privacy = 'P';
          return;
        } else {
          socialSpaceInstance = await getWorld(id);
          // socialSpaceInstance.character_worlds = socialSpaceInstance.character_worlds?.map((char: any) => char.id);
          this.fetchownVisualCollections(id);
        }
        this.currentSocialSpace = socialSpaceInstance;
        this.socialSpaceLoad();
      } catch (error) {
        toast.show('Error loading social space', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    initSocialSpace() {
      this.currentSocialSpace = {
        name: '',
        description: '',
        rooms: [],
        collections: [],
        character_worlds: [],
        cover_image: '',
        is_nsfw: false,
        triggerwarning: '',
        tags: [],
        privacy: 'P',
        visual_collections_page: {},
        extra: {},
      };
      this.chatRooms = [
        {
          title: '',
          description: '',
          banner_img_url: '',
          privacy: 'private',
          tags: [],
          extra: { _id: nanoid(6) },
        },
      ];

      const order: string[] = this.currentSocialSpace?.extra.order || Object.keys(this.currentSocialSpace);
      this.currentSocialSpace.extra.order = order.filter(
        (key: string) => !unreorderableCreatesocialspaceKeys.includes(key)
      );
      this.setAutosaveEnabled(true);
    },
    collapseDropDown(value: any) {
      this.isDropDownCollapsed = value;
    },
    changeCover(url: string) {
      if (!this.currentSocialSpace) return;
      this.currentSocialSpace.cover_image = url;
      this.markAsDirty();
    },
    clear() {
      const { reset: resetImageStore } = imageStore();
      this.currentSocialSpace = null;
      this.currentImageCollections = [];
      this.currentImageCollectionsChanged = false;
      this.autosaveFailures = 0;
      this.chatRooms = [];
      resetImageStore();
    },
    clearAll() {
      const { reset: resetImageStore } = imageStore();
      this.currentSocialSpace = null;
      this.isLoading = false;
      this.currentImageCollections = [];
      this.currentImageCollectionsChanged = false;
      this.isSaving = false;
      this.isDirty = false;
      this.autosaveFailures = 0;
      this.chatRooms = [];
      this.setAutosaveEnabled(false);
      resetImageStore();
    },
    changeKey(key: any, value: any) {
      if (!this.currentSocialSpace) return;
      this.currentSocialSpace[key] = value;
      this.markAsDirty();
    },
    changeExtraKey(key: any, value: any) {
      if (!this.currentSocialSpace) return;
      this.currentSocialSpace.extra[key] = value;
      this.markAsDirty();
    },
    updateChatroomLocal(index: number, updated: any) {
      this.chatRooms[index] = { ...this.chatRooms[index], ...updated };
      this.markAsDirty();
    },
    removeChatRoomLocal(index: number) {
      this.chatRooms.splice(index, 1);
      this.markAsDirty();
    },
    reorderChatRoomLocal(from: number, to: number) {
      this.chatRooms.splice(to, 0, this.chatRooms.splice(from, 1)[0]);
      this.markAsDirty();
    },
    addChatRoom() {
      if (this.currentSocialSpace.id) {
        this.chatRooms.push({
          title: '',
          description: '',
          banner_img_url: '',
          privacy: 'private',
          tags: [],
          id: '',
          extra: { _id: nanoid(6) },
        });
      } else {
        this.chatRooms.push({
          title: '',
          description: '',
          banner_img_url: '',
          privacy: 'private',
          tags: [],
          extra: { _id: nanoid(6) },
        });
      }
      this.markAsDirty();
    },
    setChatRooms(newChatRooms: any) {
      this.chatRooms = newChatRooms;
    },
    updateChatRoomIds(newChatrooms: any) {
      this.chatRooms.forEach((chatRoom: any) => {
        if (chatRoom.id) return;
        const newChatroom = newChatrooms.find(
          (newChatroom: any) => newChatroom.extra?._id === chatRoom.extra?._id && chatRoom.extra?._id
        );
        if (newChatroom) chatRoom.id = newChatroom.id;
      });
    },
    socialSpaceLoad() {
      const { setImageCollections } = imageStore();
      if (this.currentSocialSpace.extra.order) {
        this.currentSocialSpace.extra.order = this.currentSocialSpace.extra.order.filter(
          (key: string) => !unreorderableCreatesocialspaceKeys.includes(key)
        );
      } else {
        this.currentSocialSpace.extra.order = Object.keys(this.currentSocialSpace).filter(
          (key: string) => !unreorderableCreatesocialspaceKeys.includes(key)
        );
        const index = this.currentSocialSpace.extra.order.indexOf('description');
        if (index > -1)
          this.currentSocialSpace.extra.order.unshift(this.currentSocialSpace.extra.order.splice(index, 1)[0]);
      }
      if (this.currentSocialSpace.visual_collections_page) {
        this.currentImageCollections = this.currentSocialSpace.visual_collections_page.results as ImageCollection[];
        setImageCollections(this.currentImageCollections);
      }
      if (this.currentSocialSpace.rooms) {
        this.chatRooms = this.currentSocialSpace.rooms;
      }
      this.setAutosaveEnabled(true);
    },
    addSection(key: string) {
      const objKeys = ['visual_collections_page', 'rooms'];
      const arrKeys = ['character_worlds'];
      const normalTextKeys = ['description', 'quote', 'spotify_link'];
      if (!this.currentSocialSpace) return;

      let _newTextKey = '';

      if (key === 'text') {
        _newTextKey = newTextKey(this.currentSocialSpace.extra);
        this.currentSocialSpace.extra[_newTextKey] = {
          type: 'text',
          body: '',
          title: '',
        };
      }

      if (objKeys.includes(key)) {
        this.currentSocialSpace[key] = {};
      }

      if (arrKeys.includes(key)) {
        this.currentSocialSpace[key] = [];
      }

      if (normalTextKeys.includes(key)) {
        this.currentSocialSpace[key] = '';
      }

      const usedKey = key === 'text' ? _newTextKey : key;

      this.currentSocialSpace.extra.order = [...(this.currentSocialSpace.extra.order || []), usedKey];
    },
    removeSection(key: string) {
      if (!this.currentSocialSpace) return;
      this.currentSocialSpace = omit({ ...this.currentSocialSpace }, key);
      if (this.currentSocialSpace?.extra.order?.length) {
        this.currentSocialSpace.extra.order = this.currentSocialSpace.extra.order.filter((k: string) => k !== key);
      }
      this.markAsDirty();
    },
    changeLocalImageCollections(collections: any[]) {
      if (!this.currentSocialSpace) return;
      this.currentSocialSpace.visual_collections = collections;
      this.currentImageCollections = collections;
      this.currentImageCollectionsChanged = true;
      this.currentSocialSpace.collections = collections;
      this.markAsDirty();
    },
    changeLocalImageCollectionImageOrder(collectionId: string, images: Image[]) {
      if (!this.currentSocialSpace) return;
      const collection = this.currentImageCollections.find((c: any) => c.id === collectionId);
      if (!collection) return;
      collection.images_page!.results = images;
      reorderImageCollectionImages(collectionId, images.map((i: Image) => i.id).filter(Boolean) as string[]);
      this.markAsDirty();
    },
    async deleteSocialSpace() {
      const router = useRouter();
      if (!this.currentSocialSpace.id) {
        this.clearAll();
        this.isDone = true;
        router.replace({ name: 'user-spaces' });
        return;
      }
      try {
        await deleteWorld(this.currentSocialSpace.id);
        this.isDone = true;
        router.replace({ name: 'user-spaces' });
        toast.show('Social space deleted', 'nonative', 'primary');
        this.clearAll();
      } catch (e: any) {
        toast.show('Encountered an error while deleting social space.', 'nonative', 'danger');
      } finally {
        this.setAutosaveEnabled(false);
      }
    },
    async autosaveNew() {
      if (!this.autosaveEnabled) return;
      if (this.isSaving) return;
      if (this.currentSocialSpace.id) return;
      const { commitAllChanges } = imageStore();
      this.isSaving = true;
      try {
        const imgPayload = {} as any;
        if (this.currentSocialSpace.id) {
          imgPayload.id = this.currentSocialSpace.id;
          imgPayload.model = 'world';
        } else {
          /* initial imgPayload is empty object if creating new world */
        }
        const visualCollectionIds = await commitAllChanges(imgPayload);
        const characterIds = this.currentSocialSpace.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const payload = {
          name: this.currentSocialSpace.name || 'Untitled',
          description: this.currentSocialSpace.description,
          cover_image: this.currentSocialSpace.cover_image,
          world_rooms: this.chatRooms /* || item.id */,
          is_nsfw: this.currentSocialSpace.is_nsfw,
          characters: characterIds,
          visual_collections: visualCollectionIds,
          tags: this.currentSocialSpace.tags,
          type: 'socialspace',
          extra: this.currentSocialSpace.extra,
          privacy: 'U',
          triggerwarning: this.currentSocialSpace.triggerwarning,
          modified: this.currentSocialSpace.modified,
        };
        const response = await createWorld(payload);
        this.currentSocialSpace = {
          ...response,
          ...this.currentSocialSpace,
          modified: response.modified,
          name: this.currentSocialSpace.name || response.name,
        };
        this.updateChatRoomIds(response.rooms);
        toast.show('Social space autosaved (only visible to you)', 'nonative', 'primary');
        history.replaceState({}, '', '/social-spaces/create/' + response.id);
        this.isDirty = false;
        return response;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This social space has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.setAutosaveEnabled(false);
        } else {
          logger.error({
            e,
            loc: 'social-space-creator.autosaveNew',
            msg: 'social-space-creator AutosaveNew: ' + e,
            data: {
              world: this.currentSocialSpace,
            },
          });
          this.autosaveFailures += 1;
          if (this.autosaveFailures > 3) {
            this.setAutosaveEnabled(false);
            toast.show('Autosave failed, pausing autosave...', 'nonative', 'danger');
          } else {
            toast.show('Autosave failed, retrying...', 'nonative', 'danger');
          }
        }
      } finally {
        this.isSaving = false;
      }
    },
    async autosave() {
      if (!this.autosaveEnabled) return;
      if (this.isSaving) return;
      if (!this.currentSocialSpace.id) {
        return this.autosaveNew();
      }
      const { commitAllChanges } = imageStore();
      this.isSaving = true;
      try {
        const imgPayload = {} as any;
        if (this.currentSocialSpace.id) {
          imgPayload.id = this.currentSocialSpace.id;
          imgPayload.model = 'world';
        } else {
          /* initial imgPayload is empty object if creating new world */
        }
        const visualCollectionIds = await commitAllChanges(imgPayload);
        const characterIds = this.currentSocialSpace.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const payload = {
          name: this.currentSocialSpace.name,
          description: this.currentSocialSpace.description,
          cover_image: this.currentSocialSpace.cover_image,
          world_rooms: this.chatRooms,
          is_nsfw: this.currentSocialSpace.is_nsfw,
          characters: characterIds,
          visual_collections: visualCollectionIds,
          tags: this.currentSocialSpace.tags,
          type: 'socialspace',
          extra: this.currentSocialSpace.extra,
          privacy: this.currentSocialSpace.privacy,
          triggerwarning: this.currentSocialSpace.triggerwarning,
          id: this.currentSocialSpace.id,
          modified: this.currentSocialSpace.modified,
        } as any;
        const response = await editWorldAuto(payload.id, payload);
        this.currentSocialSpace = {
          ...response,
          ...this.currentSocialSpace,
          modified: response.modified,
          name: this.currentSocialSpace.name || response.name,
        };
        this.updateChatRoomIds(response.rooms);
        this.isDirty = false;
        return response;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This social space has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.setAutosaveEnabled(false);
        } else {
          logger.error({
            e,
            loc: 'social-space-creator.autosave',
            msg: 'social-space-creator Autosave: ' + e,
            data: {
              world: this.currentSocialSpace,
            },
          });
          this.autosaveFailures += 1;
          if (this.autosaveFailures > 3) {
            this.setAutosaveEnabled(false);
            toast.show('Autosave failed, pausing autosave...', 'nonative', 'danger');
          } else {
            toast.show('Autosave failed, retrying...', 'nonative', 'danger');
          }
        }
      } finally {
        this.isSaving = false;
      }
    },
    async saveWithIntent() {
      const { commitAllChanges } = imageStore();
      if (this.isSaving) return;
      const router = useRouter();
      let response;
      const imgPayload = {} as any;
      this.isSaving = true;
      try {
        if (this.currentSocialSpace.id) {
          imgPayload.id = this.currentSocialSpace.id;
          imgPayload.model = 'world';
        } else {
          /* initial imgPayload is empty object if creating new world */
        }
        const visualCollectionIds = await commitAllChanges(imgPayload);

        const characterIds = this.currentSocialSpace.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        if (!this.currentSocialSpace.id) {
          response = await createWorld({
            name: this.currentSocialSpace?.name,
            description: this.currentSocialSpace?.description,
            cover_image: this.currentSocialSpace?.cover_image,
            world_rooms: this.chatRooms,
            is_nsfw: this.currentSocialSpace?.is_nsfw,
            characters: characterIds,
            visual_collections: visualCollectionIds,
            tags: this.currentSocialSpace?.tags,
            type: 'socialspace',
            extra: this.currentSocialSpace?.extra,
            privacy: this.currentSocialSpace!.privacy,
            triggerwarning: this.currentSocialSpace?.triggerwarning,
          });
          this.isDone = true;
          router.push({
            name: 'social-space-details',
            params: { slug: response.slug },
          });
          this.clearAll();
        } else {
          const payload = {
            name: this.currentSocialSpace?.name,
            world_rooms: this.chatRooms,
            description: this.currentSocialSpace?.description,
            cover_image: this.currentSocialSpace?.cover_image,
            is_nsfw: this.currentSocialSpace?.is_nsfw,
            characters: characterIds,
            visual_collections: visualCollectionIds,
            tags: this.currentSocialSpace?.tags,
            type: 'socialspace',
            extra: this.currentSocialSpace?.extra,
            privacy: this.currentSocialSpace?.privacy,
            world_collections: this.currentSocialSpace?.world_collections,
            triggerwarning: this.currentSocialSpace?.triggerwarning,
            id: this.currentSocialSpace?.id,
          };
          response = await editWorld(payload.id, payload);
          this.isDone = true;
          this.isDirty = false;
          router.push({
            name: 'social-space-details',
            params: { slug: response.slug },
          });
          this.clearAll();
        }
      } catch (e: any) {
        logger.error({
          e,
          loc: 'social-space-creator.saveWithIntent',
          msg: 'social-space-creator saveWithIntent: ' + e,
          data: {
            world: this.currentSocialSpace,
          },
        });
        toast.show('Encountered an error while saving! please try again.', 'nonative', 'danger');
      } finally {
        this.isSaving = false;
      }
    },
  },
  getters: {
    socialSpace(): SocialSpace | null {
      return this.currentSocialSpace;
    },
    loading(): boolean {
      return this.isLoading;
    },
    dropdownCollapsed(): boolean {
      return this.isDropDownCollapsed;
    },
    autosaveEnabled(): boolean {
      return this._autosaveEnabled;
    },
    rooms(): any[] {
      return this.chatRooms;
    },
  },
});

export const socialSpaceCreatorStore = () => {
  const store = useSocialSpace();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
